import * as React from "react";
import classNames from "classnames";

const BrandFAQ = ({ brand }) => {
  let brandFAQContent;

  if (brand.includes("Beginning")) {
    brandFAQContent = (
      <div class="pdp-brand-faq-content">
<p>Q: What products are available on the Beginning Boutique website?</p>

<p>A: The Beginning Boutique website offers a wide range of products including clothing, shoes, accessories, and beauty products for women. They cater to various styles such as casual, formal, beachwear, and festival wear.</p>

<p>Q: Can I trust the quality of the products sold on the website?</p>

<p>A: Beginning Boutique takes pride in offering high-quality products sourced from reputable brands. They ensure that the products they sell are carefully selected and meet their quality standards. Additionally, customers can find reviews and ratings from other shoppers on the website to gauge the quality of specific products.</p>

<p>Q: Are there any special discounts or offers available on the website?</p>

<p>A: Yes, Beginning Boutique frequently runs special promotions, discounts, and offers on their website. Customers can find these deals in the "Sale" section or through their newsletter. They may also run seasonal sales, such as Black Friday or Christmas promotions.</p>

<p>Q: How do I find the right size for my clothing?</p>

<p>A: On the Beginning Boutique website, customers can find a detailed size guide for each product category. This guide includes measurements and tips on how to properly measure yourself to ensure the right fit. It is recommended to refer to this guide before making a purchase to choose the correct size</p>

<p>Q: I'm not sure about a specific product. Can I get some advice before making a purchase?</p>

<p>A: Yes, if you have any doubts or questions about a product, you can reach out to the Beginning Boutique customer support team. They have a dedicated team of fashion experts who can provide guidance and answer any inquiries you may have. You can contact them via email or through their social media channels.</p>

<p>Q: What payment methods are accepted on the website?</p>

<p>A: Beginning Boutique accepts various payment methods to provide convenience to their customers. These include major credit cards such as Visa, MasterCard, and American Express, as well as PayPal.</p>

<p>Q: How long does it take to process and dispatch an order?</p>

<p>A: Once you place an order on the Beginning Boutique website, it typically takes about 1-2 business days to process and dispatch the order. During peak times or when there are special promotions, there might be a slight delay due to increased order volumes.</p>

<p>Q: How long does it take to receive my order?</p>

<p>A: The delivery time depends on the shipping method you choose and your location. Beginning Boutique offers both standard and express shipping options. Standard shipping within Australia usually takes 2-5 business days, while express shipping takes 1-2 business days. For international orders, the delivery time may vary between 7-14 business days, depending on the destination.</p>

<p>Q: Can I track my order?</p>

<p>A: Yes, once your order has been dispatched, you will receive a confirmation email with a tracking number. This tracking number allows you to monitor the progress of your order and estimate the delivery date.</p>

<p>Q: What is the return policy?</p>

<p>A: Beginning Boutique has a hassle-free return policy. If you are not satisfied with your purchase or have received a faulty item, you can return it within 14 days of receiving it for a refund or store credit. The item must be in original condition, unworn, with tags attached. However, some items such as swimwear, lingerie, earrings, or cosmetics may not be eligible for return due to hygiene reasons.</p>

<p>Q: Is there a physical store where I can try on the clothing?</p>

<p>A: Beginning Boutique primarily operates as an online store, but they also have a retail store located in South East Queensland, Australia. If you are in that area, you can visit the store to try on the clothing.</p>

<p>Q: Are there any additional fees or charges for international orders?</p>

A: International orders may be subject to customs duties, taxes, or import fees imposed by the destination country. These charges are not included in the purchase price and are the responsibility of the customer. It is recommended to check with your local customs office for more information on any additional fees that may apply.

<p>Q: How can I stay updated on new arrivals and promotions?</p>

<p>A: Beginning Boutique offers a newsletter subscription where you can sign up with your email address to receive regular updates on new arrivals, exclusive promotions, and special discounts. You can also follow their social media channels such as Instagram, Facebook, and Twitter to stay updated with the latest news and offerings.</p>
      </div>
    );
  } else if (brand.includes("Meshki")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p>Q: What is MESHKI?</p>
<p>A: MESHKI is an Australian online fashion brand that offers stylish and trendy clothing for modern women. Founded in 2013 by two sisters, MESHKI has quickly become a go-to brand for fashion-forward individuals looking to express their individuality and confidence through unique pieces.</p>

<p>Q: What type of products does MESHKI offer?</p>
<p>A: MESHKI offers a wide range of products that cater to different fashion tastes and occasions. Their collection includes dresses, tops, bottoms, sets, swimwear, activewear, outerwear, and accessories. From elegant and sophisticated pieces to athleisure wear, MESHKI offers something for everyone, ensuring you can always find the perfect outfit for any event or mood.</p>

<p>Q: How can I place an order on MESHKI's website?</p>
<p>A: Ordering from MESHKI is easy and convenient. Simply visit their website at www.meshki.com.au, browse through their collections, and select the items you want to purchase. Once you have added everything to your cart, proceed to the checkout page to review your order and provide your shipping and payment details. After confirming your order, you will receive a confirmation email with all the necessary details.</p>

<p>Q: What are the accepted payment methods on MESHKI?</p>
<p>A: MESHKI offers various payment options to ensure a seamless shopping experience. You can pay for your order using major credit cards, such as Visa, Mastercard, and American Express. Additionally, MESHKI accepts payment through PayPal, allowing you to utilize your account balance or linked bank accounts.</p>

<p>Q: How long does it take to receive my order?</p>
<p>A: MESHKI understands the excitement of waiting for your new items to arrive, and they strive to deliver your order as quickly as possible. Upon placing your order, it will typically take 1-3 business days for it to be processed and dispatched. The delivery time may vary depending on your location and the shipping method you choose at checkout. MESHKI offers both standard and express shipping options to accommodate your preferences and urgency.</p>

<p>Q: Does MESHKI ship internationally?</p>
<p>A: Yes, MESHKI offers international shipping, allowing customers from various parts of the world to enjoy their trendy collections. However, shipping times and fees may differ for international orders, so it's recommended to check the details on their website or reach out to their customer service team for specific information regarding your location.</p>

<p>Q: Are there any shipping fees?</p>
<p>A: MESHKI provides free standard shipping on orders within Australia for purchases exceeding a certain amount. For orders below the threshold, standard shipping fees will apply. These fees vary based on the location and shipping method chosen. International orders are subject to separate shipping fees, which will be calculated at checkout based on your destination and the selected shipping method.</p>

<p>Q: Can I track my order?</p>
<p>A: Absolutely! MESHKI understands the importance of knowing the status of your order. Once your order has been dispatched, you will receive a shipping confirmation email containing a tracking number. This will allow you to monitor the progress of your package and estimate the delivery date. Simply click on the tracking number provided in the email and follow the instructions to access the tracking information.</p>

<p>Q: What is MESHKI's return policy?</p>
<p>A: MESHKI has a customer-friendly return policy to ensure you are satisfied with your purchase. If you change your mind or encounter any issues with the product, you can return it within 14 days of receiving the order. The items must be in their original condition, with all tags attached, to be eligible for a refund. However, note that certain items, such as earrings and swimwear bottoms, cannot be returned for hygiene reasons. It's always recommended to review the detailed return policy on MESHKI's website or contact their customer service for any specific concerns.</p>

<p>Q: How do I initiate a return or exchange?</p>
<p>A: To initiate a return or exchange, simply visit the MESHKI website and navigate to the Returns & Exchanges section in the footer. Follow the provided instructions and fill out the return form to request a Return Authorization Number (RA#). Once you receive the RA#, you can then proceed to send the items back to MESHKI's warehouse. It's important to carefully follow the provided guidelines to ensure a smooth return process.</p>

<p>Q: How long does it take to process returns?</p>
<p>A: After MESHKI receives your return package, it typically takes 5-7 business days to process your return and issue a refund. However, during busy periods, processing times may slightly vary. Once the return is processed, you will receive an email notification confirming the refund. The funds will be returned using the original payment method used for the purchase.</p>

<p>Q: What is the best way to contact MESHKI's customer service?</p>
<p>A: MESHKI values their customers and aims to provide excellent customer service. If you have any questions, concerns, or need assistance, you can reach out to their customer service team through various channels. Their preferred method is via the Contact Us form on their website, where you can submit your query and expect a prompt response. Additionally, you can also contact them through their social media platforms, such as Instagram and Facebook, where they are active and responsive.</p>

<p>Q: Does MESHKI have a physical store?</p>
<p>A: MESHKI predominantly operates as an online retailer; however, they have recently expanded to physical stores in select locations. Currently, they have stores in Sydney, Perth, and Melbourne, Australia. These physical stores provide an opportunity for customers to experience the brand's products in person and receive personalized assistance from their team members.</p>

<p>Q: Does MESHKI offer any special discounts or promotions?</p>
<p>A: Yes, MESHKI regularly offers exciting promotions and discounts to provide value to their customers. They often have seasonal sales, discount codes, and limited-time offers, allowing you to grab your favorite pieces at enticing prices. It's recommended to subscribe to their newsletter or follow their social media accounts to stay updated on the latest promotions and never miss out on any deals.</p>
    </div>)
  }else if (brand.includes("Vrg") || brand.includes("VRG")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p>Q: What is VRG GRL?</p>
<p>A: VRG GRL is a trendy fashion brand that focuses on creating stylish and unique dresses for women. They offer a wide range of dresses that cater to different occasions and personal preferences.</p>

<p>Q: What is the brand's philosophy?</p>
<p>A: VRG GRL aims to empower women by providing them with stylish dresses that make them feel confident and fabulous. Their designs celebrate individuality and encourage women to express their personal style.</p>

<p>Q: Where can I find VRG GRL dresses?</p>
<p>A: You can find VRG GRL dresses exclusively on their website, https://www.vrggrl.com/. They offer a convenient and secure online shopping experience, allowing you to browse and purchase their dresses from the comfort of your own home.</p>

<p>Q: What types of dresses does VRG GRL offer?</p>
<p>A: VRG GRL offers a wide variety of dress styles to cater to different occasions and preferences. Some popular types of dresses you can find in their collection include mini dresses, midi dresses, maxi dresses, bodycon dresses, wrap dresses, shift dresses, and more.</p>

<p>Q: Are VRG GRL dresses suitable for formal events?</p>
<p>A: Yes, VRG GRL offers a range of dresses suitable for formal events. Their collection includes elegant and sophisticated designs that are perfect for occasions such as weddings, galas, or cocktail parties.</p>

<p>Q: Can I find casual dresses at VRG GRL?</p>
<p>A: Absolutely! VRG GRL understands that not every occasion requires a formal dress. They offer a wide selection of casual dresses that are perfect for everyday wear, brunches, or casual outings with friends.</p>

<p>Q: What fabrics are used in VRG GRL dresses?</p>
<p>A: VRG GRL focuses on using high-quality fabrics to ensure both style and comfort. Their dresses are crafted from a variety of fabrics such as polyester, cotton, satin, velvet, and blends that offer a luxurious feel on the skin.</p>

<p>Q: Are VRG GRL dresses true to size?</p>
<p>A: VRG GRL provides detailed size charts for each dress on their website to help you find the perfect fit. It is recommended to refer to the size chart and measure yourself before placing an order. Each dress may have different measurements, so it's essential to double-check before purchasing.</p>

<p>Q: Can I return a VRG GRL dress if it doesn't fit me?</p>
<p>A: Yes, VRG GRL has a return policy that allows you to return or exchange unworn, unwashed, and undamaged dresses within a specified timeframe. Please refer to their website for detailed information on their return policy and procedures.</p>

<p>Q: Does VRG GRL ship internationally?</p>
<p>A: Yes, VRG GRL ships their dresses worldwide. They offer international shipping to many countries, ensuring that women worldwide can enjoy their stylish dresses.</p>

<p>Q: How long does it take to receive my VRG GRL dress?</p>
<p>A: The shipping time varies depending on your location and the shipping method you choose. Once your order is placed, VRG GRL processes and dispatches it within 1-3 business days. Shipping times may differ for domestic and international orders, and you can find estimated delivery times on their website.</p>

<p>Q: How do I take care of my VRG GRL dress?</p>
<p>A: VRG GRL provides care instructions for each dress on their website and inside the garment tag. It is recommended to follow these instructions to maintain the quality and longevity of your dress. Generally, most dresses can be hand-washed or machine-washed in cold water and hung to dry or tumble-dried on low heat.</p>

<p>Q: Are VRG GRL dresses affordable?</p>
<p>A: VRG GRL offers dresses at different price points to cater to various budgets. Their pricing reflects the quality of their designs and fabrics. Keep an eye out for any ongoing promotions or sales on their website, as they sometimes offer discounts on selected items.</p>

<p>Q: How can I stay updated on new VRG GRL dresses and promotions?</p>
<p>A: To stay up-to-date with the latest VRG GRL dresses and promotions, you can sign up for their newsletter on their website. By subscribing, you will receive regular updates on new arrivals, exclusive offers, and occasional discounts.</p>

<p>Q: Can I find reviews or customer photos of VRG GRL dresses?</p>
<p>A: Yes, on their website, VRG GRL has a section dedicated to customer reviews, where you can find honest opinions and feedback from customers who have purchased their dresses. Additionally, you can also check their social media platforms or search for hashtags related to VRG GRL to find customer photos and real-life styling ideas.</p>

<p>Q: Does VRG GRL offer any rewards or loyalty programs?</p>
<p>A: Currently, VRG GRL does not offer a specific rewards or loyalty program. However, subscribing to their newsletter allows you to be among the first to know about any exclusive offers or discounts they may provide.</p>
    </div>)
  }else if (brand.includes("Princess")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p>Q: What is Princess Polly?</p>
<p>A: Princess Polly is an Australian fashion brand that offers a diverse range of clothing and accessories for women. They are known for their trendy and chic styles, designed to help women feel confident and fashionable.</p>

<p>Q: Where can I purchase Princess Polly products?</p>
<p>A: You can purchase Princess Polly products online directly through their website, which is www.princesspolly.com.au. They offer worldwide shipping, so no matter where you are, you can enjoy their fashion-forward pieces.</p>

<p>Q: What types of products does Princess Polly offer?</p>
<p>A: Princess Polly offers a wide range of products, including dresses, tops, bottoms, outerwear, swimwear, accessories, and shoes. They have everything you need to put together a stylish and fashionable outfit from head to toe.</p>

<p>Q: Can you tell me more about the dresses available at Princess Polly?</p>
<p>A: Princess Polly has a stunning collection of dresses suitable for various occasions. They offer mini, midi, and maxi dresses, as well as bodycon, skater, wrap, and slip styles. Whether you are looking for a cute summer dress or a classy cocktail dress, Princess Polly has got you covered.</p>

<p>Q: Are the dresses at Princess Polly true to size?</p>
<p>A: Princess Polly provides a detailed size guide on their website to help customers find the perfect fit. It's always a good idea to refer to the measurements provided and compare them to your own body measurements to ensure you choose the right size. If you are still uncertain, their customer service team is always happy to assist you with any sizing questions.</p>

<p>Q: Does Princess Polly offer international shipping?</p>
<p>A: Yes, Princess Polly offers international shipping to many countries around the world. Shipping costs and delivery times may vary depending on your location, so it's best to check their website for more information on international shipping.</p>

<p>Q: Can I return or exchange a dress if it doesn't fit me?</p>
<p>A: Yes, Princess Polly has a hassle-free return and exchange policy. If you receive a product that doesn't fit or meet your expectations, you can return it within 30 days for an exchange, store credit, or refund. Make sure to follow the instructions provided on their website for a smooth return process.</p>

<p>Q: Does Princess Polly offer plus-size dresses?</p>
<p>A: Yes, Princess Polly recognizes the importance of inclusivity and offers a range of sizes, including plus sizes, in their dress collection. They understand that every body is different and aim to cater to a diverse customer base.</p>

<p>Q: What is the price range of dresses at Princess Polly?</p>
<p>A: The price range of dresses at Princess Polly can vary depending on the style, fabric, and design. Generally, their dresses range from around $50 to $200 AUD. However, they often have sales and promotions, so you may find some great deals and discounts.</p>

<p>Q: Are Princess Polly dresses made from sustainable materials?</p>
<p>A: Princess Polly is committed to making sustainable choices and reducing their environmental impact. While not all of their products are made from sustainable materials, they do offer a selection of sustainable and eco-friendly options. Look for their "Sustainable Styles" section on their website to find dresses and other products made from sustainable fabrics.</p>

<p>Q: Can I find dresses for special occasions at Princess Polly?</p>
<p>A: Yes, Princess Polly offers a range of dresses suitable for special occasions such as weddings, parties, and formal events. They have elegant and sophisticated styles that will make you stand out in the crowd. From lace to sequins, you'll find a variety of options to suit your taste and the occasion.</p>

<p>Q: How often does Princess Polly release new dress collections?</p>
<p>A: Princess Polly is known for its fast-paced fashion cycle, and they release new collections frequently. They stay up to date with the latest trends and designs, ensuring that their customers always have access to the most fashionable pieces.</p>

<p>Q: Can I get styling tips or outfit ideas from Princess Polly?</p>
<p>A: Absolutely! Princess Polly has an engaging blog on their website where they share styling tips, outfit ideas, and inspiration. They also have an active social media presence, especially on Instagram, where they showcase their customers' styling choices, helping you get some fashion inspiration.</p>

<p>Q: Does Princess Polly have a loyalty program or rewards system?</p>
<p>A: Yes, Princess Polly has a loyalty program called "Polly Perks." It allows customers to earn points on their purchases, which they can then redeem for discounts on future orders. It's a great way to save money while shopping your favorite styles.</p>

<p>Q: Can I purchase Princess Polly gift cards?</p>
<p>A: Yes, Princess Polly offers gift cards that make a perfect present for fashion-loving friends and family members. The gift cards are available in various denominations, and recipients can use them to purchase any items they desire from the Princess Polly website.</p>

<p>Q: How can I stay updated with the latest Princess Polly news and promotions?</p>
<p>A: To stay informed about new releases, promotions, and sales, you can subscribe to the Princess Polly newsletter from their website. Additionally, following them on social media platforms like Instagram and Facebook will keep you in the loop about their latest updates.</p>

      
    </div>)
  }else if (brand.includes("Outcast")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p>Q: What is Outcast Clothing?</p>
<p>A: Outcast Clothing is a renowned fashion brand that offers chic and stylish clothing items for fashion-forward individuals. Their vast collection ranges from dresses to jumpsuits, tops to bottoms, and everything in between. With a keen eye for the latest trends, Outcast Clothing ensures that their customers stay ahead of the fashion game.</p>

<p>Q: What are some of the product types offered by Outcast Clothing?</p>
<p>A: Outcast Clothing specializes in dresses, playsuits, jumpsuits, swimwear, tops, bottoms, and accessories. Their focus is primarily on women's fashion, catering to various styles and preferences. Whether you prefer casual, classy, or edgy looks, you can find the perfect outfit that suits your individual taste.</p>

<p>Q: What makes Outcast Clothing unique?</p>
<p>A: Outcast Clothing sets itself apart with its commitment to offering high-quality, on-trend apparel at affordable prices. They carefully curate their collections to ensure that each piece is stylish, well-made, and suitable for a wide range of occasions.</p>

<p>Q: Are the dresses available only for specific seasons or occasions?</p>
<p>A: Not at all! Outcast Clothing understands that fashion is not limited to one season or occasion. This is why they offer a diverse range of dresses suitable for various events, such as weddings, parties, casual outings, or even just a date night. Their dresses are designed to be versatile, allowing you to dress them up or down depending on the occasion.</p>

<p>Q: What are some popular dress styles available at Outcast Clothing?</p>
<p>A: Outcast Clothing caters to different fashion preferences, so you can find various dress styles to suit your individual taste. Some popular dress styles available include maxi dresses, midi dresses, bodycon dresses, mini dresses, wrap dresses, and off-shoulder dresses. Each style comes in a myriad of colors, prints, and patterns to ensure you find the perfect match for your personal style.</p>

<p>Q: Are Outcast Clothing's dresses true to size?</p>
<p>A: Outcast Clothing provides a detailed size guide for each product on their website to help customers find the perfect fit. It's essential to check the measurements carefully before making a purchase to ensure a satisfactory fit. If you're unsure about the sizing, you can always contact their customer support for assistance.</p>

<p>Q: Can I find dresses in plus sizes at Outcast Clothing?</p>
<p>A: Yes! Outcast Clothing is committed to inclusivity and believes that fashion should be accessible to everyone. Therefore, they offer a range of dresses in plus sizes to cater to different body types and sizes.</p>

<p>Q: How can I style the dresses from Outcast Clothing?</p>
<p>A: The beauty of Outcast Clothing's dresses is their versatility. You can style them according to your unique taste and the occasion. Pair a floral maxi dress with a denim jacket and ankle boots for a boho-chic look, or dress up a bodycon dress with statement heels and accessories for a night out. The options are endless, and Outcast Clothing's dresses provide a solid foundation for creating your own personal style.</p>

<p>Q: Can I purchase Outcast Clothing dresses online?</p>
<p>A: Absolutely! Outcast Clothing operates primarily online, making it convenient for customers to browse, select, and purchase their favorite dresses from the comfort of their own homes. Simply head to their website, explore their extensive dress collection, and add the dresses that catch your eye to your cart. With secure payment options and worldwide shipping, Outcast Clothing ensures a seamless online shopping experience.</p>

<p>Q: Is there a return or exchange policy at Outcast Clothing?</p>
<p>A: Yes, indeed! Outcast Clothing values customer satisfaction and offers a hassle-free return and exchange policy. If you are not completely satisfied with your purchase, you can return the item within a specified timeframe (as mentioned on their website) for a refund or exchange. However, it's important to note that certain conditions and requirements apply, so be sure to review their return policy for further details.</p>

<p>Q: How can I stay updated with Outcast Clothing's latest releases and promotions?</p>
<p>A: Outcast Clothing keeps its customers in the loop about new arrivals, exclusive promotions, and sales through various channels. To stay updated, make sure to sign up for their newsletter on their website and follow them on social media platforms such as Instagram and Facebook. This way, you'll be the first to know about the hottest trends and exciting discounts.</p>

      
    </div>)
  }else if (brand.includes("Petal")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p>Q: What is Petal & Pup?</p>

<p>A: Petal & Pup is an Australian-based online fashion brand that offers an exquisite collection of clothing, primarily focusing on women's dresses and related accessories. Their aim is to provide fashionable and affordable pieces, with a blend of trendy and timeless styles to suit every woman's taste.</p>

<p>Q: What types of products does Petal & Pup offer?</p>

<p>A: Petal & Pup specializes in women's fashion, particularly dresses. From casual and everyday wear to formal and party outfits, they have a wide array of choices to cater to various occasions. Apart from dresses, they also offer accessories like handbags, jewelry, hats, and scarves, which can enhance your overall look and style.</p>

<p>Q: What makes Petal & Pup unique?</p>

<p>A: One of the distinct features of Petal & Pup is their commitment to providing affordable fashion without compromising quality. They understand that women desire stylish clothing that fits well, flatters their figures, and doesn't break the bank. That's why they ensure that their pieces are reasonably priced, allowing customers to look and feel their best without burning a hole in their pocket.</p>

<p>Q: Is Petal & Pup an ethical brand?</p>

<p>A: Yes, Petal & Pup is dedicated to being an ethical fashion brand. They strive to work with suppliers who meet their ethical standards and comply with fair and safe labor practices. Petal & Pup believes in the importance of sustainability, and they aim to reduce their environmental impact by using eco-friendly packaging and materials whenever possible.</p>

<p>Q: How can I find the perfect dress on Petal & Pup's website?</p>

<p>A: Petal & Pup has made it easy for customers to find their dream dress through their user-friendly website. You can start by navigating their menu, where you'll find different categories for dresses, such as Maxi, Midi, Mini, Wrap, Floral, and more. You can also use the search bar to type in specific keywords or browse by color, occasion, or size to narrow down your options.</p>

<p>Q: Do they offer plus-size dresses?</p>

<p>A: Yes, Petal & Pup believes in inclusivity and offers a range of plus-size dresses to cater to different body types and sizes. You can find a dedicated section on their website labeled "Curve" that showcases stylish dresses designed specifically for curvier women. This collection celebrates all body shapes, allowing everyone to feel confident and beautiful.</p>

<p>Q: Are the dresses true to size?</p>

<p>A: Petal & Pup provides detailed sizing charts for each dress on their website to help customers find the perfect fit. They recommend measuring yourself and comparing the measurements to their size guide to ensure accuracy. However, it's essential to note that sizing can vary between brands, so it's always wise to double-check the measurements to avoid any disappointment.</p>

<p>Q: Can I return an item if it doesn't fit or I'm not satisfied?</p>

<p>A: Yes, Petal & Pup has a hassle-free returns policy. If the item doesn't fit or you're not entirely satisfied, you can return it within 30 days of receiving your order for an exchange or refund. The item must be in its original condition with tags attached. However, please note that certain items, such as sale items or earrings, may not be eligible for return due to hygiene reasons.</p>

<p>Q: Do they ship internationally?</p>

<p>A: Yes, Petal & Pup offers worldwide shipping so that customers can enjoy their beautiful dresses no matter where they are located. They have various shipping options available, including express shipping for those needing their items sooner. International shipping times may vary depending on the destination country.</p>

<p>Q: Can I find maternity dresses on Petal & Pup?</p>

<p>A: Absolutely! Petal & Pup understands that pregnant women also deserve stylish and comfortable clothing options. They offer a maternity collection with dresses designed specifically to accommodate growing bumps while keeping fashion at the forefront. From flowy maxi dresses to form-fitting bodycon styles, there is something delightful for every mama-to-be.</p>

<p>Q: Can I find elegant dresses suitable for formal events?</p>

<p>A: Yes, Petal & Pup has a stunning variety of elegant dresses perfect for formal events such as weddings, black-tie galas, or fancy dinners. Their collection includes floor-length gowns, sophisticated midi dresses, and timeless cocktail dresses with intricate details and luxurious fabrics. You can elevate your style with their range of elegant dresses that will have you feeling like a true fashion icon.</p>

<p>Q: Can I purchase matching accessories on Petal & Pup's website?</p>

<p>A: Definitely! Petal & Pup offers a curated selection of accessories that complement their dresses flawlessly. Whether you're looking for a statement handbag, dainty jewelry pieces, or a stylish hat to complete your outfit, you'll find plenty of options to choose from. Accessories can elevate your look and add that extra touch of personality to your ensemble.</p>

      
    </div>)
  }else if (brand.includes("Fetch")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p>Q: What is That's So Fetch?</p>
<p>A: That's So Fetch is an online clothing brand based in Australia that offers a wide range of trendy and fashionable clothing items for women. From dresses and tops to swimwear and activewear, they've got you covered for every occasion.</p>

<p>Q: What type of clothing does That's So Fetch offer?</p>
<p>A: That's So Fetch offers a diverse range of clothing items. You can find stylish and chic dresses, trendy tops, cozy knitwear, fashionable denim, stunning swimwear, active gym wear, comfy loungewear, and much more. They strive to keep up with the latest fashion trends and provide options that cater to every style.</p>

<p>Q: Are the dresses available from That's So Fetch suitable for all body types?</p>
<p>A: That's So Fetch believes that fashion should be inclusive and accessible to everyone, regardless of their body type. They offer dresses in a variety of sizes, ranging from XS to XL, ensuring that all women can find the perfect fit for their body shape. They understand the importance of feeling confident and comfortable in what you wear, and that's why they provide different styles and silhouettes to suit all body types.</p>

<p>Q: What types of dresses can I find at That's So Fetch?</p>
<p>A: That's So Fetch offers a wide variety of dresses to suit different occasions and personal styles. Whether you're looking for a casual sundress for a day at the beach, a stylish maxi dress for a summer party, a chic cocktail dress for a special event, or a formal gown for a black-tie affair, they have got the perfect dress for you. From flowy bohemian designs to fitted bodycon styles, their dress collection caters to all tastes.</p>

<p>Q: Can I find party dresses at That's So Fetch?</p>
<p>A: Absolutely! That's So Fetch has a fantastic selection of party dresses that will make you stand out at any celebration. From sequin embellished mini dresses to elegant off-the-shoulder styles, their party dress collection offers something for every party-goer. Whether you prefer a classic little black dress or a bold and colorful option, you'll find the perfect party dress to make a statement.</p>

<p>Q: Are there any casual dresses available at That's So Fetch?</p>
<p>A: Yes, indeed! If you're looking for something comfortable and casual, That's So Fetch has a range of laid-back and easy-to-wear dresses. From simple t-shirt dresses to flowy boho-inspired midi dresses, they offer options to suit everyday wear. These dresses are perfect for running errands, meeting friends, or enjoying a relaxed day out.</p>

<p>Q: Are the dresses at That's So Fetch on-trend and fashionable?</p>
<p>A: Absolutely! That's So Fetch prides itself on staying up-to-date with the latest fashion trends. They carefully curate their dress collection to offer customers the most fashionable and trendy options. Whether it's a specific color, print, or style that's currently in vogue, you can count on That's So Fetch to provide dresses that will keep you looking stylish and on-trend.</p>

<p>Q: Can I find formal dresses at That's So Fetch?</p>
<p>A: Yes, you can! That's So Fetch offers a selection of stunning formal dresses that are perfect for those special occasions or formal events. Whether you're attending a wedding, a gala, or a prom, their formal dress collection has a variety of elegant options to choose from. From floor-length gowns to sophisticated midi dresses, you'll find the perfect formal attire to make a lasting impression.</p>

<p>Q: Are the dresses made with high-quality materials?</p>
<p>A: That's So Fetch is committed to providing customers with high-quality clothing. They source their dresses and other clothing items from trusted manufacturers and suppliers to ensure both style and durability. By prioritizing quality, they aim to deliver clothing that will not only make you look fabulous but also stand the test of time.</p>

<p>Q: Do the dresses from That's So Fetch come in different sizes?</p>
<p>A: Yes, That's So Fetch offers dresses in a range of sizes to accommodate different body types. From XS to XL, they strive to be as inclusive as possible and cater to a wide range of sizes. Each dress on their website includes a size chart and measurements to guide you in choosing the right size for yourself.</p>

<p>Q: How can I purchase dresses from That's So Fetch?</p>
<p>A: Purchasing dresses from That's So Fetch is super easy and convenient. Simply visit their website, browse through their dress collection, and select the dress you love. Click on the desired dress, choose your size and quantity, and add it to your cart. Once you're ready, proceed to the checkout, fill in your shipping details, and make payment. Voila! Your dress will be on its way to you.</p>

<p>Q: What if the dress I purchased doesn't fit me or meet my expectations?</p>
<p>A: That's So Fetch understands that sometimes what you see online may differ from the actual product or the fit might not be perfect. If the dress you purchased doesn't meet your expectations, you can return it within 14 days of receiving it. They have a hassle-free return policy that allows you to exchange the dress for a different size or style, or receive a refund. Make sure to check their website for the detailed return instructions and policies.</p>

<p>Q: Does That's So Fetch offer international shipping?</p>
<p>A: Yes, That's So Fetch ships internationally, so you can enjoy their trendy dresses no matter where you are in the world. They offer various shipping options, and the delivery cost and timeframes will depend on your location. The website provides detailed information on their international shipping services, including estimated delivery times and fees.</p>

<p>Q: Are there any additional charges involved when purchasing from That's So Fetch?</p>
<p>A: Generally, the price you see on the website is the final price you'll have to pay for the dress. However, keep in mind that additional charges, such as shipping fees, customs duties, or taxes, may apply depending on your location. It's always a good idea to review That's So Fetch's shipping and customs policies to be</p>

      
    </div>)
  }else if (brand.includes("Supre")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p>Q: What is Supré?</p>
<p>A: Supré is a popular fashion brand based in Australia that offers a wide range of trendy clothing for women. They specialize in providing affordable and fashionable dresses, especially in the categories of Mini, Midi, Maxi, Casual, and Formal dresses.</p>

<p>Q: Can you tell me more about Supré's dresses?</p>
<p>A: Certainly! Supré offers a diverse selection of dresses that suit various occasions and personal styles. Whether you're looking for a chic mini dress for a night out, a comfortable midi dress for everyday wear, an elegant maxi dress for a special event, or a versatile casual or formal dress, Supré has got you covered.</p>

<p>Q: What makes Supré's dresses unique?</p>
<p>A: Supré's dresses stand out for their trendy designs, quality materials, and affordable prices. The brand constantly keeps up with the latest fashion trends and ensures their dresses reflect current styles. Additionally, Supré understands the need for comfortable and durable clothing, so you can expect their dresses to be made from high-quality fabrics that are both fashionable and long-lasting.</p>

<p>Q: Are Supré dresses suitable for all body types?</p>
<p>A: Absolutely! Supré believes in inclusivity and offers dresses that cater to various body types. Their extensive size range ensures that women of all shapes and sizes can find dresses that fit them perfectly and make them feel confident and beautiful.</p>

<p>Q: How can I shop for Supré dresses?</p>
<p>A: Shopping for Supré dresses is easy and convenient. You can either visit their physical stores located across Australia or explore their extensive online store at supre.com.au. Online shopping allows you to browse their entire collection, compare styles and prices, and place an order from the comfort of your own home.</p>

<p>Q: Does Supré offer international shipping?</p>
<p>A: Yes, Supré offers international shipping, allowing customers from around the world to enjoy their fashionable dresses. Simply select your country during the checkout process, and Supré will deliver the dresses directly to your doorstep.</p>

<p>Q: Can I return or exchange a dress if it doesn't fit or meet my expectations?</p>
<p>A: Of course! Supré understands that sometimes a dress may not be the perfect fit or meet your expectations. They offer a hassle-free returns and exchange policy within 30 days of purchase. You can either return the dress to a physical store or arrange for a return through their online process.</p>

<p>Q: How do I know which size is right for me?</p>
<p>A: Supré provides a handy size guide on their website, which helps you determine the correct size for each dress. The guide includes detailed measurements to ensure you find the perfect fit. If you're unsure or in between sizes, it's always a good idea to consult the size guide or reach out to Supré's customer service for further assistance.</p>

<p>Q: Can I find dresses for special occasions like weddings or parties at Supré?</p>
<p>A: Absolutely! Supré offers a range of formal dresses that are perfect for weddings, parties, or any special occasion where you want to dress elegantly. Their formal dresses showcase sophistication and style, so you can look your best for those memorable events.</p>

<p>Q: What can I expect in terms of pricing for Supré dresses?</p>
<p>A: Supré is known for its affordable fashion, and their dresses are attractively priced without compromising on quality. While prices vary depending on the style and fabric, you'll find that Supré offers great value for trendy and durable dresses.</p>

<p>Q: Can I find matching accessories or shoes to complement my Supré dress?</p>
<p>A: Definitely! Supré understands the importance of completing an outfit, so they also offer a range of fashion accessories and shoes. Whether you're looking for a stylish handbag, statement jewelry, or trendy footwear, Supré has the perfect additions to complete your look.</p>

<p>Q: Does Supré offer any discounts or promotions on their dresses?</p>
<p>A: Yes, Supré frequently runs promotions and offers discounts on their dresses and other products. By subscribing to their newsletter or following them on social media, you can stay updated on the latest offers and grab some fantastic deals.</p>

<p>Q: Can I find sustainable dresses at Supré?</p>
<p>A: Yes, Supré has a commitment to sustainability and offers a range of sustainable dresses as part of their Supre Foundation Collection. These dresses are made from eco-friendly materials and ethically sourced. By choosing these options, you can contribute to a more sustainable fashion industry.</p>

<p>Q: Are Supré dresses suitable for all age groups?</p>
<p>A: Supré caters primarily to the trendy and fashion-conscious women, typically aged 16-30. However, their dresses often have versatile designs that can appeal to a broader age range. Whether you're a teenager, young adult, or even a more mature woman, there's a high chance you'll find a dress that suits your style at Supré.</p>

<p>Q: Can I find plus-size dresses at Supré?</p>
<p>A: Yes, Supré recognizes the need for inclusivity and offers a range of plus-size dresses. They believe that fashion should be accessible to all, regardless of body size, and strive to provide trendy options in extended sizes to ensure every woman feels comfortable and confident in their dresses.</p>

<p>Q: What is Supré's sustainability initiative?</p>
<p>A: Supré is committed to reducing their environmental impact and promoting sustainable practices. They have implemented various sustainability initiatives, such as using environmentally friendly materials, reducing waste, and supporting ethical manufacturing processes. You can learn more about their sustainability efforts on their website.</p>

<p>Q: Can I find trendy casual dresses at Supré?</p>
<p>A: Absolutely! Supré is well-known for its casual dresses that are perfect for everyday wear. Their range includes comfortable and stylish options such as t-shirt dresses, summer dresses, and casual midi dresses. These dresses are designed to be versatile, allowing you to effortlessly transition from day to night with just a few accessories.</p>

<p>Q: What are some popular dress styles at Supré?</p>
<p>A: Supré offers an ever-evolving collection of dress styles, but some popular choices include bodycon dresses, wrap dresses, slip dresses, off-the-shoulder dresses, and skater dresses. These styles are known for their</p>
      
    </div>)
  }else if (brand.includes("Forever")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
     Q: What is Forever New?

<p>A: Forever New is a popular Australian fashion brand that offers a wide range of women's clothing and accessories. They specialize in providing trendy and stylish dresses, including mini, midi, and maxi dresses, suitable for both casual and formal occasions.</p>

<p>Q: What types of dresses does Forever New offer?</p>

<p>A: Forever New offers a diverse collection of dresses to cater to different styles and preferences. You can find Mini dresses that are perfect for a fun night out, featuring shorter lengths that showcase your legs and add a flirty touch to your look. Midi dresses, with their medium length, are an ideal choice for semi-formal events or everyday wear. For those looking for elegance and sophistication, Maxi dresses are available in a range of styles, from flowy and bohemian to more tailored and formal designs.</p>

<p>Q: Can I find both casual and formal dresses at Forever New?</p>

<p>A: Yes, Forever New offers a wide selection of dresses that cater to both casual and formal occasions. Whether you're attending a beach wedding or a corporate event, you'll find dresses that suit your needs. They have dresses made from various fabrics, such as cotton or linen for casual settings, and luxurious materials like silk or satin for more formal events.</p>

<p>Q: How can I find the perfect dress for me on Forever New's website?</p>

<p>A: Forever New has made it easy for shoppers to find their perfect dress. On their website, you can browse through different categories, such as "Dresses," and filter your search based on dress length, occasion, color, and size. Additionally, product descriptions, images, and customer reviews will assist you in making an informed decision.</p>

<p>Q: Does Forever New offer any promotions or discounts?</p>

<p>A: Yes, Forever New frequently offers promotions and discounts to provide its customers with great value. They often have seasonal sales, such as end-of-season sales or special discounts during major holidays. Additionally, they offer promotions like "buy one, get one at a discounted price" or percentage discounts on selected items. Keep an eye on their website or subscribe to their newsletter to stay updated on their latest promotions.</p>

<p>Q: What is Forever New's return policy?</p>

<p>A: Forever New understands that sometimes a purchase may not meet your expectations, and they want you to be satisfied with your shopping experience. If you change your mind about a purchase made online, you can return the item(s) within 14 days of delivery for a full refund. The item(s) should be in their original condition, unworn, unwashed, and with all original tags attached. Please note that swimwear, earrings, and cosmetics cannot be returned for hygiene reasons. For more details on </p>their return policy, visit their website.

<p>Q: Can I return an item purchased online to a Forever New physical store?</p>

<p>A: Yes, if you prefer, you can return an item purchased online to any Forever New store within the country of purchase. Ensure that you have all the required documentation, including your proof of purchase and the item(s) in their original condition. The store team will be happy to assist you with the return.</p>

<p>Q: How long does it take to process a refund from Forever New?</p>

<p>A: Once Forever New receives your returned items and verifies that they meet the return conditions, they will process your refund. It usually takes between 3-5 business days for the refund to be credited back to the original form of payment. However, depending on your bank or payment provider, it may take additional time for the funds to reflect in your account.</p>

<p>Q: Does Forever New ship internationally?</p>

<p>A: Yes, Forever New does ship internationally to selected countries. During the checkout process, you can select your country from the drop-down list to see if they deliver to your location. Please note that international shipping costs and delivery times may vary depending on the destination.</p>

<p>Q: Can I track my order from Forever New?</p>

<p>A: Yes, once your order has been dispatched, Forever New will provide you with a tracking number via email. You can use this tracking number to monitor the progress of your delivery through your selected shipping carrier's website.</p>

<p>Q: Does Forever New offer any additional services, such as alterations or personalized styling advice?</p>

<p>A: Forever New does not offer in-house alteration services; however, they can recommend trusted tailors in your area who may assist you with alterations. Regarding personalized styling advice, Forever New has a styling team available to answer your fashion-related questions and provide guidance. You can reach out to them via phone or email for advice on outfit combinations or styling suggestions.</p>

<p>Q: Are Forever New's dresses true to size?</p>

<p>A: Forever New offers a helpful size guide on their website to assist you in finding the perfect fit. Their dresses typically follow standard sizing; however, it is always advisable to refer to the measurements provided in the size guide for each garment. Additionally, customer reviews can also provide valuable insights into the fit and sizing of specific dresses.</p>

<p>Q: Does Forever New offer gift cards?</p>

<p>A: Yes, Forever New offers gift cards that can be a great option when you want to give someone the freedom to choose their favorite dress or accessory. The gift cards are available in various denominations and can be purchased online or in-store. They can be used both online and in Forever New physical stores, making them a convenient gifting option.</p>

<p>Q: Can I return or exchange an item purchased with a gift card?</p>

<p>A: Yes, you can return or exchange an item purchased with a gift card. If you choose to return an item, the refund will be credited back onto a virtual gift card for the same value. If you wish to exchange an item, the value of the item being returned will be credited back onto a virtual gift card, which can then be used towards the purchase of the desired item.</p>

<p>Q: Does Forever New have a loyalty program?</p>

<p>A: Yes, Forever New offers a loyalty program called "The Forever You Rewards." By signing up for this program, you can earn points on every purchase, which can then be redeemed for discounts on future purchases. Additionally, members receive exclusive access to promotions, early sale previews</p>

      
    </div>)
  }else if (brand.includes("Twosisters")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p>Q: What is Two Sisters the Label?</p>
<p>A: Two Sisters the Label is a trendy and fashion-forward brand that offers a wide range of dresses, including Mini, Midi, and Maxi dresses. They cater to various occasions, including both casual and formal events. With an extensive collection, Two Sisters the Label provides fashionable options for women who want to make a statement.</p>

<p>Q: What type of dresses does Two Sisters the Label offer?</p>
<p>A: Two Sisters the Label offers a diverse selection of dresses, including Mini, Midi, and Maxi dresses. Their Mini dresses are perfect for a night out or a casual gathering, while Midi dresses are suitable for both formal and casual occasions. If you're looking for something more glamorous, their Maxi dresses are designed to make a statement.</p>

<p>Q: How can I determine the right dress size for me?</p>
<p>A: Two Sisters the Label provides a size guide on their website to help you determine the best fit for you. It is important to measure yourself accurately and refer to their size chart to ensure you select the correct size. If you are unsure, you can also reach out to their customer support for further assistance.</p>

<p>Q: What type of fabrics are used for Two Sisters the Label dresses?</p>
<p>A: Two Sisters the Label prioritizes quality and comfort, and they use a variety of high-quality fabrics for their dresses. The specific fabric used for each dress varies and can include polyester, chiffon, lace, and satin, among others. The information on the fabric composition can be found on the product page of each dress.</p>

<p>Q: Can I wear Two Sisters the Label dresses to formal events?</p>
<p>A: Absolutely! Two Sisters the Label offers a range of dresses suitable for formal events. Their Maxi dresses, in particular, are designed to make a statement and are perfect for formal occasions, such as weddings, galas, or black-tie events. Additionally, their Midi dresses can be styled up to create an elegant and polished look.</p>

<p>Q: Are Two Sisters the Label dresses suitable for everyday wear?</p>
<p>A: Indeed! Two Sisters the Label understands the need for versatile clothing, and many of their dresses can be styled for everyday wear. Their Mini and Midi dresses are perfect for a casual day out or can be dressed up with accessories and footwear. You can create multiple looks with their dresses to suit different occasions.</p>

<p>Q: Does Two Sisters the Label offer promotions or discounts?</p>
<p>A: Yes, Two Sisters the Label frequently offers promotions and discounts on their dresses. They believe in making fashion accessible and affordable to all women. You can keep an eye on their website or subscribe to their newsletter to receive updates on upcoming sales, new arrivals, and promotional offers.</p>

<p>Q: What are the different types of promotions offered by Two Sisters the Label?</p>
<p>A: Two Sisters the Label offers various promotions throughout the year. Some of the promotions they offer include seasonal sales, where you can get discounts on selected items or even site-wide discounts. They also occasionally offer limited-time promotions, such as buy-one-get-one-free or exclusive discounts for newsletter subscribers.</p>

<p>Q: Can I return or exchange a dress purchased from Two Sisters the Label?</p>
<p>A: Two Sisters the Label has a return policy that allows customers to return or exchange items within 14 days of receiving their order. The dress must be in its original condition, unwashed, and with all tags attached. To initiate a return or exchange, you need to contact their customer support team and follow their instructions. Please note that certain items, such as sale items or items purchased during promotional periods, may have different return policies, so it's essential to review the specific terms and conditions.</p>

<p>Q: Will I be refunded for a returned item from Two Sisters the Label?</p>
<p>A: If your return complies with Two Sisters the Label's return policy, they will issue a refund for the purchase price of the item, minus any shipping charges. The refund will be processed to your original payment method within a reasonable timeframe after they receive the returned item. However, it's important to note that it may take a few additional business days for the refund to be reflected in your account, depending on your bank or credit card provider.</p>

<p>Q: Can I cancel or modify my order after placing it with Two Sisters the Label?</p>
<p>A: Once an order is placed with Two Sisters the Label, it is processed for shipping as quickly as possible. Therefore, it is not possible to cancel or modify an order once it has been placed. However, if you need assistance or have any questions about your order, you can reach out to their customer support team, and they will be happy to assist you.</p>

<p>Q: Is international shipping available from Two Sisters the Label?</p>
<p>A: Yes, Two Sisters the Label offers international shipping to many countries worldwide. The shipping cost and estimated delivery time may vary depending on your location. You can find more information about international shipping on their website, or you can contact their customer support for specific details regarding your country.</p>

<p>Q: How can I reach Two Sisters the Label's customer support?</p>
<p>A: Two Sisters the Label offers customer support via email. You can reach out to their support team by sending an email to the address provided on their website. They aim to respond to all inquiries within a reasonable timeframe and are always ready to assist you with any questions or concerns you may have.</p>

<p>Q: Are Two Sisters the Label dresses limited in stock?</p>
<p>A: Two Sisters the Label produces their dresses in limited quantities to maintain exclusivity and reduce waste. Due to this, some popular styles may sell out quickly. However, they often introduce new designs or restock popular items, so it's always a good idea to check their website regularly or subscribe to their newsletter to stay updated on the latest releases.</p>

<p>Q: Can I request a custom dress from Two Sisters the Label?</p>
<p>A: Two Sisters the Label does not offer custom-made dresses at this time. However, they have an extensive range of ready-to-wear dresses in various sizes and styles to cater to different preferences. You can explore their collection and choose from the available options that best match your style and preferences.</p>

      
    </div>)
  }else if (brand.includes("Baby")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p><strong>Q: What is the story behind Babyboo Fashion?</strong></p>
<p>A: Babyboo Fashion is an Australian-based brand established in 2011, known for its sexy and up-to-date fashion preferences that have since created a fun-filled shopping experience for women worldwide. They continually strive to stay atop the fashion game, making women feel stylish, confident, and sexy.</p>

<p><strong>Q: What does Babyboo Fashion specialize in? </strong></p>
<p>A: Babyboo Fashion specializes in creating one-of-a-kind pieces for women, especially in the categories of party dresses, evening wear, casual wear, and swimwear. They focus on trendy and fashionable clothing that gives women confidence and style.</p>

<p><strong>Q: How does Babyboo Fashion manage to stay atop the fashion game? </strong></p>
<p>A: To stay current in the fast-paced fashion world, the Babyboo Fashion team is ever watchful of the global trends. They strive to design and produce fashionable, trendy, and attractive clothing for women that aligns with the current and upcoming fashion trends.</p>

<p><strong>Q: How often does Babyboo Fashion launch new designs? </strong></p>
<p>A: Babyboo Fashion takes pride in continually stirring its pot of creativity. They launch new designs and collections every month, keeping their clothing line fresh and up-to-date at all times.</p>

<p><strong>Q: How does Babyboo Fashion maintain its product quality? </strong></p>
<p>A: Babyboo Fashion is adamant about its product quality. They have stringent procedures for quality inspections, covering each design step, from sample creation to product packaging, ensuring everything aligns well with their top-grade quality standards.</p>

<p><strong>Q: How does Babyboo Fashion contribute to the environment? </strong></p>
<p>A: Babyboo Fashion is an eco-conscious brand. They use biodegradable and compostable shipping mailers as a part of their efforts towards supporting a sustainable environment.</p>

<p><strong>Q: What is the mission of Babyboo Fashion? </strong></p>
<p>A: Babyboo Fashion's mission is to empower women by offering them high-quality, stylish, and trendy fashion choices. They aim to continually provide a unique shopping experience, helping women uncover their style within the comfort of their homes.</p>

<p><strong>Q: Do they offer international shipping? </strong></p>
<p>A: Yes, Babyboo Fashion offers worldwide shipping, aiming to reach as many fashion-conscious women globally as possible.</p>

<p><strong>Q: What is the visual language that Babyboo Fashion adopts for its filtering options? </strong></p>
<p>A: Babyboo Fashion has an innovative visual feature on its website which represents each occasion by a specific color, helping customers to filter and choose dresses based on the event they are shopping for.</p>

<p><strong>Q: What extra options does Babyboo Fashion provide to its customers? </strong></p>
<p>A: Babyboo Fashion stands for customer convenience; therefore, it provides options like Shop Now, Pay Later and express worldwide shipping. Plus, they maintain a comprehensive size guide to help customers choose the perfect fit.</p>

      
    </div>)
  }else if (brand.includes("Pepper")) {
    brandFAQContent = (<div class="pdp-brand-faq-content">
<p><strong>Question: What is Peppermayo?</strong></p>

<p><strong>Answer:</strong> Peppermayo is a one-stop fashion hub that offers a wide variety of trendy apparel, accessories, beauty products, and even home stuff. It is based in Sydney, Australia, and ships internationally.</p>

<p><strong>Question: How can I place an order?</strong></p>

<p><strong>Answer:</strong> To place an order, you just need to browse through our site, choose your desired product and add it to your shopping cart. Once you're done shopping, you can click on your cart and proceed to checkout.</p>

<p><strong>Question: How can I track my order?</strong></p>

<p><strong>Answer:</strong> After your order is shipped, a tracking number will be provided so that you can easily monitor the progress of your shipment online. If you have an account with us, you can log in to your account and find all the necessary information about your orders.</p>

<p><strong>Question: What if I want to return or exchange my order?</strong></p>

<p><strong>Answer:</strong> Peppermayo offers a free returns service for all their Australian customers. You can initiate a return in your account and we'll take care of the rest. For more information, kindly check out our returns policy on the website.</p>

<p><strong>Question: What about international orders, how do I return them?</strong></p>

<p><strong>Answer:</strong> We do accept returns from our international customers, however, any associated return shipping costs or custom charges are shouldered by the customer.</p>

<p><strong>Question: Can I change or cancel my order after it’s been placed?</strong></p>

<p><strong>Answer:</strong> Unfortunately, once your order is placed, we cannot guarantee any changes or cancellations as we aim to dispatch orders as quickly as possible. However, we suggest contacting us immediately if you have issues with your order and we'll do our best to assist you.</p>

<p><strong>Question: What payment methods do you accept?</strong></p>

<p><strong>Answer:</strong> We accept payments through PayPal, Afterpay, Apple Pay, Google Pay, Klarna, and all major credit cards such as Visa, Mastercard, and American Express.</p>

<p><strong>Question: How secure is my online order?</strong></p>

<p><strong>Answer:</strong> Security is a top priority at Peppermayo. We ensure that your personal information is kept secure at all times. All your payment information is encrypted during transmission and stored in a secure environment.</p>

<p><strong>Question: How often do you restock?</strong></p>

<p><strong>Answer:</strong> Peppermayo constantly updates its inventory with new and exciting items. For out of stock items, we can't guarantee a restock but we encourage you to check back regularly for updates.</p>
      
    </div>)
}else if (brand.includes("Charcoal Clothing")) {
  brandFAQContent = (<div class="pdp-brand-faq-content">
<p>Q: What is Charcoal Clothing?</p>

<p>A: Charcoal Clothing is an online fashion brand headquartered in Brisbane, Australia. Established in 2017, the brand aims to bring customers closer to global trends by offering affordable and stylish fashion products.</p>

<p>Q: Who are the founders of Charcoal Clothing?</p>

<p>A: Charcoal Clothing was founded by two sisters, Danielle and Natalia, who were inspired by international fashion trends and wanted to bring them to everyday Australian women at an affordable price.</p>

<p>Q: What inspired the creation of Charcoal Clothing?</p>

<p>A: The sisters' love for fashion prompted them to start Charcoal Clothing. They were inspired by the fashion trends they saw while travelling the world and wanted to bring them to their doorstep. Their goal is to inspire women to be the best versions of themselves, expressing their individuality and confidence through their fashion choices.</p>

<p>Q: What range of products does Charcoal Clothing offer?</p>

<p>A: Charcoal Clothing offers a wide array of women's clothing, including dresses, tops, bottoms, jackets, accessories and many more. They race to bring global fashion trends to their customer base with a quick turnover of styles.</p>

<p>Q: Does Charcoal Clothing cater to a specific demographic?</p>

<p>A: Not specifically, Charcoal Clothing aims to inspire all women, regardless of age or size. They want every woman to feel confident, comfortable, and fashionable in their clothes.</p>

<p>Q: How does Charcoal Clothing keep up with the latest fashion trends?</p>

<p>A: The team at Charcoal Clothing keeps a close eye on international trends by following fashion influencers, designers, and attending fashion shows. They also listen to their customers' preferences and actively engage with them on social media.</p>

<p>Q: How often are new styles added to Charcoal Clothing's collection?</p>
 
<p>A: Charcoal Clothing is dedicated to refreshing their styles continuously to match the fast-paced nature of fashion. They drop new styles weekly, guaranteeing that there's always something new and exciting for their customers.</p>

<p>Q: What is unique about Charcoal Clothing's proposition?</p>

<p>A: Charcoal Clothing stands out because of their focus on offering stylish, trendy, and high-quality clothes at an affordable price. Their commitment to fast-fashion ensures their customers stay current without having to break the bank. They strive to offer a personalized shopping experience while maintaining a positive, fun brand attitude.</p>

<p>Q: How would you describe Charcoal Clothing's brand personality?</p>

<p>A: Charcoal Clothing's brand personality is trendy, fast-paced, and vibrant. It encourages every woman to express her individuality through her fashion choices, without compromising on comfort and style. After all, their motto is: 'Fashion is about being yourself'.</p>
  </div>)
}

  return (
    <div id="pdp-brand-container" className="pt-4 pb-0">
      {brandFAQContent}
    </div>
  );
};

export default BrandFAQ;
